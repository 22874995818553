import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/wisdom-for-wisdom.scss"


const W4WPage = () => {
  const CLIENT = "Fresno Oral Maxillofacial Surgery & Dental Implant Center"
  const LOCAL_STUDENTS = "three local students"
  const CITY = "Fresno"
  const APPLICATION_PERIOD = "June 17, 2021"

  const WISDOM_FACTS = [
    "Wisdom teeth are impacted when they cannot grow in normally, getting trapped in the jaw bone or gums.",
    "Wisdom teeth are the only teeth that don’t form before birth.",
    "85% of wisdom teeth will need to be removed at some point.",
    "Wisdom teeth are also known as third molars.",
    "Wisdom teeth often appear between the ages of 17 and 25.",
  ]

  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-main">
        <div className="w4w-header">
          <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/w4w-logo-2021.svg" />
        </div>
        <div className="w4w-top-container">
          <div className="w4w-image">
            <img src="https://res.cloudinary.com/nuvolum/image/upload/c_scale,w_1121/v1/Programs/w4w-hero-image-2021.jpg" />
          </div>
          <img
            className="w4w-logo"
            src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/w4w-logo-mark-2021.jpg"
          />
          <div className="image-overlay">
            <img
              className="w4w-left is-hidden-mobile"
              src="https://res.cloudinary.com/nuvolum/image/upload/c_scale,w_280/v1618849555/Programs/w4w-left-section-2021-close.png"
            />
            <img
              className="w4w-left is-hidden-tablet"
              src="https://res.cloudinary.com/nuvolum/image/upload/c_scale,w_280/v1618849555/Programs/w4w-left-section-2021-mobile.png"
            />
          </div>
          <p className="w4w-top-container__text is-hidden-tablet">
            Apply for a chance to receive free wisdom teeth removal.
          </p>
        </div>
        <div className="w4w-mid-container">
          <div className="list-container">
            <div className="facts">
              <h3 className="list-header">Did you know...</h3>
              <ul className="wisdom-facts">
                {WISDOM_FACTS.map((fact, i) => (
                  <li key={i + "-fact"}>• {fact}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mid-paragraph">
            <p className="mid-text">
              Fresno Oral Maxillofacial Surgery & Dental Implant Center is proud
              to help students through its Wisdom For Wisdom program. This
              program provides free wisdom teeth removal to three local students
              to help offset some of their college expenses, like textbooks.
            </p>

            <p>
              Trinity, 19, from Sanger is a student at Fresno City College. In
              her application she spoke about how the Wisdom For Wisdom program
              would lift a weight off her shoulders and help her balance school,
              work, and being a young adult. Trinity also mentioned wanting to
              protect her smile and prevent any other teeth from shifting out of
              alignment.
            </p>

            <p>
              Aubree, 22, from Fresno is a student at California State
              University, Fresno. In her application she wrote about the pain
              she has been dealing with from her wisdom teeth, but said that all
              of her focus and finances have been on her education as she works
              to complete her Bachelor of Arts degree.
            </p>

            <p>
              Shayla, 18, from Clovis, just graduated from high school and plans
              to attend Whittier College where she will study Kinesiology and
              work towards becoming a physical therapist. In her application she
              also mentioned that in addition to studying she will also be
              playing for the Whittier College Women's Soccer Team and working
              to help support herself through college.
            </p>

            <p>
              Follow Trinity, Aubree, and Shayla's treatment journeys on{" "}
              <a
                href="https://www.facebook.com/fresnooralsurgery/"
                title="Facebook Page"
                target="_blank"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/fresnooralsurgery/"
                title="Instagram Page"
                target="_blank"
              >
                Instagram
              </a>
              .
            </p>

            <p>
              <em>
                Learn more about{" "}
                <a
                  href="/procedure/wisdom-teeth-removal-fresno-ca/"
                  title="Wisdom Teeth Removal in Fresno, CA"
                >
                  wisdom teeth removal
                </a>{" "}
                and all of our{" "}
                <a href="/procedure" title="Procedures Page">
                  procedures
                </a>
                .
              </em>
            </p>

            {/* <TimedSwapElement
                swapDate='18 Jun 2021 09:00:00 PDT'
                beforeElement={
                <>
            <p>
              The application period is open now through <b>{APPLICATION_PERIOD}.</b>
            </p>
                </>
                }
                afterElement={
                  <p><strong style={{color: "#007BA9", fontSize: "21px", lineHeight: "28px"}}>Thank you for your interest in our Wisdom For Wisdom program. Our 2021 application period is closed, but stay tuned on our Facebook page to hear about the 2022 program!</strong></p>
                }
              /> */}
          </div>
          {/* <TimedElement
              endDate='18 Jun 2021 09:00:00 PDT'
            >
          <div className="apply-container">
            <h3>
              Applying is as <span style={{ fontWeight: 800 }}>easy</span> as{" "}
              <span style={{ fontWeight: 800 }}>1, 2, 3!</span>
            </h3>
            <div className="apply-content">
              <div className="one-two-three-wrapper">
                <div className="hl"></div>
                <div className="one-two-three">
                  <div className="one-two-three__item">1</div>
                  <div className="one-two-three__item">2</div>
                  <div className="one-two-three__item">3</div>
                </div>
              </div>
              <div className="steps">
                <div>Watch the video.</div>
                <div className="long">
                  <span>
                  Take the quiz.
                  </span>
                </div>
                <div>Complete your application.</div>
              </div>
            </div>
          </div>
          </TimedElement> */}
        </div>
        {/* <div className="w4w-bottom-container">
        <TimedElement
              endDate='18 Jun 2021 09:00:00 PDT'
            >
          <div className="w4w-sub-paragraph">
            <h3>
              We wish every student the best as they pursue their academic
              endeavors.
            </h3>
          </div>
          <div className="w4w-button">
            <Button
              buttonText="STEP 1: WATCH THE VIDEO"
              href="/wisdom-step-1/"
              contained
            ></Button>
          </div>
          </TimedElement>
        </div> */}
        {/* <section className="application-section color-back">
        <div className="columns has-text-centered">
          <div className="column">
              <h2>Wisdom for Wisdom Application</h2>
            </div>
          </div>
            <div className="columns">
            <div className="column is-6"></div>
            <div className="column">

           
            <div className="application-wrapper"><Seamless src="/w4wContactForm.js" /></div>
            </div>
            <div className="column is-6"></div>
            </div>

            
          </section> */}
      </div>
    </Layout>
  )
}

export default W4WPage
